import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/wamp/www/docz/sgc-documentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Alert } from './Alert.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "alert"
    }}>{`Alert`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Alert} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Alert kind=\"warning\">Some message</Alert>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Alert kind="warning" mdxType="Alert">Some message</Alert>
    </Playground>
    <h2 {...{
      "id": "using-different-kinds"
    }}>{`Using different kinds`}</h2>
    <Playground __position={2} __code={'<Alert kind=\"info\">Some message</Alert>\n<Alert kind=\"positive\">Some message</Alert>\n<Alert kind=\"negative\">Some message</Alert>\n<Alert kind=\"warning\">Some message</Alert>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Alert kind="info" mdxType="Alert">Some message</Alert>
  <Alert kind="positive" mdxType="Alert">Some message</Alert>
  <Alert kind="negative" mdxType="Alert">Some message</Alert>
  <Alert kind="warning" mdxType="Alert">Some message</Alert>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      